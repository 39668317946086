const articles = [
	{
		title: "SP287 - What made Apollo a success?",
		comment: "NASA Report",
//		img: "/articles/apollo-card.png",
		img: "/articles/SP287-Apollo-Article.png",
		href: "/comingsoon/",
		tag: "Engineering",
		homepage: true
	},
	{
		title: "DDSP: Differentiable Digital Signal Processing.",
		comment: "Scientific article",
		img: "/articles/DDSP-Article.png",
		href: "/comingsoon/",
		tag: "Machine learning",
		homepage: true
	},

//	{
//		title: "SoundStream: An End-to-End Neural Audio Codec",
//		comment: "Scientific article",
//		img: "https://placehold.co/403x771",
//		href: "",
//		tag: "Machine learning",
//		homepage: true
//	},
//	{
//		title: "CREPE: A Convolutional Representation for Pitch Estimation",
//		comment: "Scientific article",
//		img: "https://placehold.co/403x771",
//		href: "",
//		tag: "Machine learning",
//		homepage: true
//	},
//	{
//		title: "Audio Spectrogram Classification using Deep Convolutional Neural Networks",
//		comment: "Scientific article",
//		img: "https://placehold.co/403x771",
//		href: "",
//		tag: "Machine learning",
//		homepage: true
//	},
];

export default articles;
