import { faGithub, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const INFO = {
	main: {
		title: "Chloé Lavrat",
		name: "Chloé Lavrat",
		jobName: "Interdisciplinary Machine Learning Engineer",
		tagline: ["Transforming complex ideas", "into tangible solutions"],
		profileImage: "profil.jpg"
	},

	socials: {
		github: {
			link:"https://github.com/chloelavrat",
			icon: faGithub
		},
		linkedin: {
			link:"https://www.linkedin.com/in/clavrat",
			icon: faLinkedin
		},
		instagram: {
			link:"https://instagram.com/c.lavrat",
			icon: faInstagram
		}
	},

	homepage:{
		expertise:{
			title: "Expertise Hub",
			blockA: {
				title: "Interdisciplinary Approach",
				subtitle: "Bridging Gaps in Science",
				description: "With a robust background spanning multiple scientific fields, I bridge the gap between theoretical research and practical application."
			},
			
			blockB: {
				title: "Advanced Analytical Skills",
				subtitle: "Solving Complex Problems",
				description: "Skilled at tackling intricate challenges and developing innovative solutions through precise analysis and critical thinking."
			},
			blockC: {
				title: "Leadership and Innovation",
				subtitle: "Driving Success",
				description: "Proven ability to lead interdisciplinary projects from concept to completion, ensuring innovative solutions that meet goals."
			},
			techLogos: [
				{ src: "/logo/tech/pytorch.png", alt: "PyTorch" },
				{ src: "/logo/tech/tensorflow.png", alt: "TensorFlow" },
				{ src: "/logo/tech/python.png", alt: "Python" },
				{ src: "/logo/tech/matlab.png", alt: "Matlab" },
				{ src: "/logo/tech/ccppcsharp.png", alt: "c / cpp / c#" },
				{ src: "/logo/tech/streamlit.png", alt: "Streamlit" },
				{ src: "/logo/tech/docker.png", alt: "Docker" },
				{ src: "/logo/tech/react.png", alt: "React" },
			]
		},
	
		projects: {
			title: "My Projects",
			description: ["This section features a selection of projects that reflect my work in addressing complex challenges.", "Each project highlights practical solutions and technical skills applied to real-world problems."]
		},

		quote: {
			sentence: "Act in your place with the world",
			author: "Édouard Glissant"
		},

		articles:{
			title: "My Articles",
			description: ["This section includes articles that explore various topics within science and engineering.", "Here, you’ll find insights and discussions on recent developments and key concepts."]
		},

		contact: {
			title: "Have an idea?",
			subtitle: "Let's connect.",
			description: "I'm a passionate Engineer that loves to resolve complex problems with simple, state of the art solutions.",
			contactMeLink: "https://www.linkedin.com/in/clavrat/"
		}
	},
	
	about: {
		subtitle: "Discover my tech playground!",
		copyright: [
			{"name":"©Geraldine Aresteanu", "link":"https://geraldinearesteanu.com", "type": "Portraits"},
			{"name":"©Delphine Le Boulaire", "link":"https://www.delphineleboulaire.com", "type": "Projects"}
		],
		description:
			["I’m a passionate engineer specializing in machine learning, driven by the thrill of solving complex problems and creating innovative solutions. My background in information theory and machine learning fuels my dedication to developing state-of-the-art systems that blend creativity with engineering excellence.", "Explore my projects to see machine learning in action, check out my articles for insights, and don’t hesitate to connect. Let’s discover technology’s potential together!"],

		experiences: [
		{
			title: "Research and Innovation",
			place: "Azerty-Labs",
			logo: "logo/company/azerty-labs-logo.png",
			date: "2020 – Present",
			description: "Develop and implement embedded and real-time AI synthesis models while designing and producing analog and digital synthesizers.",
		},
		{
			title: "Research Engineer - Audio Machine Learning Tech Lead",
			place: "Ircam Amplify",
			logo: "logo/company/ircamamplify-logo.png",
			date: "2022 – 2024",
			description: "Develop and integrate advanced audio machine learning algorithms, such as voice cloning, audio source separation, into embedded systems and Saas.",
		},
		{
			title: "AI Research Intern",
			place: "Sony CSL",
			logo: "/logo/company/sony-csl-logo.png",
			date: "2020 – 6 months",
			description: "Research and development of a sound synthesis model. Development of a real-time acoustic instrument transfer model.",
		},
		{
			title: "Quality of Service Validation",
			place: "Orange",
			logo: "/logo/company/orange-logo.png",
			date: "2017 – 2 months",
			description: "Ensured the quality of service for new French 2G/3G/4G mobile network productions and established production guidelines for 4G deployment.",
		},
		],
		education: [
		{
			title: "Master’s Degree in Science and Music",
			place: "Ircam Sorbonne University",
			logo: "/logo/company/ircam-logo.png",
			date: "2019 – 2020",
			description: "Implementation of physical models of musical instruments (strings, winds, percussion). Development of a granular synthesizer based on machine learning (VAE).",
		},
		{
			title: "Academic Semester",
			place: "Polytechnic of Turin",
			logo: "/logo/company/polito-logo.png",
			date: "2017 – 2018",
			description: "Developed software for Android and IoT, including a social network app and a connected smoke detector utilizing AWS and Data Lake. Specialized in graphics computing and acoustic measurement..",
		},
		{
			title: "General Engineering Degree",
			place: "IMT Atlantic",
			logo: "/logo/company/imt-logo.png",
			date: "2016 – 2021",
			description: "Expertise in signal processing and information theory for telecommunications, with a focus on embedded computing, antenna design, machine learning and hardware implementation.",
		},
		{
			title: "Bachelor’s Degree in Engineering Sciences",
			place: "Villebon-Georges Charpak Institute",
			logo: "/logo/company/ivgc-logo.png",
			date: "2013 – 2016",
			description: "Bachelor’s degree with a multidisciplinary focus in animal and plant biology, physics, mathematics, machine learning, and project management.",
		},
		],
	},

	articles: {
		title: "Articles",
		description: "Explore a curated selection of articles that reflect my interests in the intersection of advanced AI and machine learning with practical applications. Each piece highlights how innovative solutions address resource constraints and interdisciplinary challenges, offering insights into the evolving landscape of technology and its real-world impacts."
	},

	projects:{
		title: "Projects",
		description: "Discover a collection of my key projects that illustrate my passion for applying advanced technologies to real-world challenges. Each project showcases my approach to solving complex problems through innovative solutions, emphasizing the practical impact of my work. Explore how these initiatives reflect my commitment to advancing the field and addressing critical needs with creativity and precision."
	}

};

export default INFO;
